export enum AmorcageAdhesionErreur {
    AGENCE_NON_AUTORISEE = 'AGENCE_NON_AUTORISEE',
    UTILISATEUR_DESACTIVEE = 'UTILISATEUR_DESACTIVEE',
    DOUBLON_PARCOURS_ASSURANCE = 'DOUBLON_PARCOURS_ASSURANCE',
    NATIONALITE_NON_AUTORISEE = 'NATIONALITE_NON_AUTORISEE',
    PAYS_NAISSANCE_NON_AUTORISE = 'PAYS_NAISSANCE_NON_AUTORISE',
    FORMAT_DONNEES_INVALIDE = 'FORMAT_DONNEES_INVALIDE',
    FORMAT_EMAIL_INVALIDE = 'FORMAT_EMAIL_INVALIDE',
    ERREUR_TECHNIQUE = 'ERREUR_TECHNIQUE'
}

export enum StatutAssuranceGroupDCU {
    SIMULATION = 'SIMULATION',
    SIGNATURE_EN_COURS = 'SIGNATURE_EN_COURS',
    QS_TRANSMIS_ASSUREUR = 'QS_TRANSMIS_ASSUREUR',
    ACCORD_ASSUREUR_RECU = 'ACCORD_ASSUREUR_RECU',
    ACCORD_ASSUREUR_VALIDE = 'ACCORD_ASSUREUR_VALIDE'
}

export enum StatutAssuranceGroupDCS {
    SIMULATION = 'SIMULATION',
    FORMALITES_ADHESION = 'FORMALITES_ADHESION',
    FORMALITES_MEDICALES = 'FORMALITES_MEDICALES',
    DECISION = 'DECISION',
    FINALISATION = 'FINALISATION',
    SANS_SUITE = 'SANS_SUITE'
}
