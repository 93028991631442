import { DATE_DD_MM_YYYY_FR, getAgeFromBirthDate, isCloseToTodaysMonth, isValidDateMaxAge, reformatDate } from '@core/common'
import { AssuranceOption, AssuranceType, ValidationFieldError } from '../../model'
import { ActiviteFormUI } from '../../model/activite/ActiviteFormUI'
import { ErrorMessage } from '../../components/SaisieErrorMessages'
import { DATE_YYYY_MM_DD } from '../../model/format'
import { AssuranceFormPropsUI } from '../../model/assurance/AssuranceFormUI'

export const VALIDATION_ERRORS_MESSAGES = {
  WRONG_VALUE: {
    quotite: {
      title: 'Quotité',
      message: 'La quotité doit être comprise entre 0 et 100 %.',
    },
  },
  TOO_OLD_TYPE: {
    type: {
      title: 'Assurance',
      message: 'Le client n’est pas éligible à l’assurance groupe car l’âge limite d’adhésion est plafonné à 75 ans.',
    },
  },
  TOO_OLD_FORMULE: {
    formule: {
      title: 'Assurance',
      message: 'Le client n’est pas éligible à la perte d’emploi car l’âge limite d’adhésion est supérieur à l’âge autorisé.',
    },
  },
  NO_VALIDE_CONTRAT_TYPE: {
    formule: {
      title: 'Assurance',
      message: 'La formule choisie n’est pas compatible avec le type d’activité. Merci de modifier la formule.',
    },
  },
}

export const AGE_WARNING_MESSAGES = {
  AGE_ABOVE_75_YEARS_OLD: {
    title: 'Assurance',
    message: ('Le client peut devenir inéligible à l’assurance groupe prochainement car l’âge limite d’adhésion est plafonné à 75 ans.'),
  },
}

const VALIDE_TYPE_CONTRAT = [
  'TITULAIRE_FONCTION_PUBLIQUE',
  'STAGIAIRE_FONCTION_PUBLIQUE',
  'CDI',
]

const checkContratType = activities => {
  if (!activities || !activities?.length) {
    return true
  }
  return activities?.some((activite: ActiviteFormUI) => (
    !!activite.typeContrat && VALIDE_TYPE_CONTRAT.includes(activite.typeContrat)
  ))
}


export function getAssuranceWarnings(assurance: {emprunteur?: AssuranceFormPropsUI, coEmprunteur?: AssuranceFormPropsUI}): AssuranceWarnings {
  let coEmprunteurWarningNeeded = false
  const emprunteurWarningNeeded = isWarningNeeded(assurance?.emprunteur)

  if (assurance?.coEmprunteur) {
    coEmprunteurWarningNeeded = isWarningNeeded(assurance?.coEmprunteur)
  }

  if (emprunteurWarningNeeded || coEmprunteurWarningNeeded) {
    return {
      warnings: [{ field: AGE_WARNING_MESSAGES.AGE_ABOVE_75_YEARS_OLD.title, message: AGE_WARNING_MESSAGES.AGE_ABOVE_75_YEARS_OLD.message }],
      warningMessages: [{ message: AGE_WARNING_MESSAGES.AGE_ABOVE_75_YEARS_OLD.message, title: AGE_WARNING_MESSAGES.AGE_ABOVE_75_YEARS_OLD.title }],
      positionFirstWarning: 0,
    }
  }
  return {
    warnings: [],
    warningMessages: [],
    positionFirstWarning: -1,
  }
}

export function isWarningNeeded(person) {
  let age = 0
  let isAlmost75years = false

  if (person.type !== AssuranceType.SANS_ASSURANCE) {
    age = getAgeFromBirthDate(reformatDate(person.naissanceDate, DATE_DD_MM_YYYY_FR, DATE_YYYY_MM_DD))
  }
  if (age === 74) {
    isAlmost75years = isCloseToTodaysMonth(reformatDate(person.naissanceDate, DATE_DD_MM_YYYY_FR, DATE_YYYY_MM_DD))
  }
  return isAlmost75years
}

export interface AssuranceWarnings {
  warnings: ValidationFieldError[]
  warningMessages: ErrorMessage[]
  positionFirstWarning: number
}

const hasTypeEligble = ({ naissanceDate, type }) => isValidDateMaxAge(naissanceDate, 75, undefined) || type !== AssuranceType.ASSURANCE_GROUP

const hasFormuleEligible = ({ naissanceDate, formule }) => isValidDateMaxAge(naissanceDate, 60, undefined) || formule !== AssuranceOption.DC_PTIA_ITT_PE

const validateType = (type ?: AssuranceType) : ValidationFieldError[] => {
  if (!type) {
    return [{ field: 'type', message: 'MISSING_VALUE' }]
  }
  if (!Object.keys(AssuranceType).includes(type)) {
    return [{ field: 'type', message: 'WRONG_VALUE' }]
  }
  return []
}

const validateFormule = (formule ?: AssuranceOption) : ValidationFieldError[] => !formule ? [{ field: 'formule', message: 'MISSING_VALUE' }] : []

const validateQuotite = (quotite ?: number): ValidationFieldError[] => {
  if (!quotite && quotite !== 0) {
    return [{ field: 'quotite', message: 'MISSING_VALUE' }]
  }
  if (quotite <= 0 || quotite > 100) {
    return [{ field: 'quotite', message: 'WRONG_VALUE' }]
  }
  return []
}

const validateGlobale = (assurance: any) : ValidationFieldError[] => {
  const errors: any[] = []
  if (assurance?.naissanceDate) {
    if (assurance?.naissanceDate && assurance?.type && !hasTypeEligble(assurance)) {
      errors.push({ field: 'type', message: 'TOO_OLD_TYPE' })
    }
    if (assurance?.naissanceDate && assurance?.formule && !hasFormuleEligible(assurance)) {
      errors.push({ field: 'formule', message: 'TOO_OLD_FORMULE' })
    }
  }
  if (assurance?.formule === AssuranceOption.DC_PTIA_ITT_PE && !checkContratType(assurance?.activities)) {
    errors.push({ field: 'formule', message: 'NO_VALIDE_CONTRAT_TYPE' })
  }
  return errors
}

export const validateAssurance = (assurance : any) => {
  if (assurance?.type === AssuranceType.SANS_ASSURANCE) {
    return []
  }
  const typeErrors = validateType(assurance?.type)
  if (typeErrors.length) return typeErrors

  return [
    ...validateGlobale(assurance),
    ...validateFormule(assurance?.formule),
    ...validateQuotite(assurance?.quotite),
  ]
}

const mergeGlobalErrors = (emprunteurErrors: ValidationFieldError[], coEmprunteurErrors: ValidationFieldError[]) => [
  ...emprunteurErrors,
  ...coEmprunteurErrors,
].map(error => error?.message && VALIDATION_ERRORS_MESSAGES[error.message]?.[error.field]).filter(error => error)

export function validateAssurances({ emprunteur, coEmprunteur, hasCoEmprunteur }) {
  const emprunteurErrors = validateAssurance(emprunteur)
  const coEmprunteurErrors = hasCoEmprunteur ? validateAssurance(coEmprunteur) : []
  const hasErrors = emprunteurErrors.length + coEmprunteurErrors.length > 0
  return {
    globalErrors: mergeGlobalErrors(emprunteurErrors, coEmprunteurErrors),
    emprunteurErrors,
    coEmprunteurErrors,
    hasErrors,
  }
}
